import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import {
  Box, Heading, Text, Center, Divider, Stack, Button, Flex,
} from '@chakra-ui/react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import AsideBox from '../components/AsideBox';

import { defaultStyles, workAheadStyles } from '../styles';
import { mapDataToVariants, defaults, ping } from '../util';

const PageHome = ({
  data, params, location, pageContext,
}) => {
  const { variantName } = pageContext;
  const variants = mapDataToVariants(data.allAirtable.nodes, {
    description: 'description',
    title: 'title',
  });
  const content = defaults(variants.default, variants[variantName]);

  useEffect(ping, []);

  return (
    <Layout title={content.title} description={content.description} location={location}>
      <Heading as="h1" fontSize="6xl" mb="10">Make your work work for you</Heading>
      <Text fontSize="xl">Need reliable data, helpful tools and tailored content to inform your career questions?</Text>
      <Text fontSize="xl">Work ahead is a free tool to help you achieve your highest professional quality of life.</Text>
      <Divider my="10" />
      <Text mb="6">Let's take the first step to getting the information that matters to you.</Text>

      <Box py="8" bg="white" mb="10" sx={{ ...workAheadStyles.fullWidthBackground }}>
        <Heading size="l" mb="4">Is your profession stable enough to get you to retirement?</Heading>
        <Flex>
          <Box maxWidth="50%">
            <Text>We pulled together the factors that influence future job prospects to give you a holistic view of the risks you may face along the path to retirement. </Text>
          </Box>
          <Center flex="1">
            <Button as={Link} to="/job-stability/calculator" variant="outline" colorScheme="black" size="lg">Start planning</Button>
          </Center>
        </Flex>
      </Box>

      <Stack direction="row" bg="white" sx={{ ...workAheadStyles.fullWidthBackground }}>
        <Box py="8">
          <Heading size="l" mb="4">How friendly is your career to remote work?</Heading>
          <Text mb="4">We have the data from thousands of job postings to tell us which occupations and industries you are most likely to find a job with flexible working arrangements</Text>
          <Center>
            <Button as={Link} to="/flexible-work/calculator" variant="outline" colorScheme="black" size="lg">Let's check</Button>
          </Center>
        </Box>

        <Box py="8">
          <Heading size="" mb="4">Will your quality of life be impacted if you move?</Heading>
          <Text mb="4">We take a balanced view of the changes that may influence your income and a broad view of living expenses to help you understand the impact of a move.</Text>
          <Center>
            <Button as={Link} to="/wages/calculator" variant="outline" colorScheme="black" size="lg">Have a look</Button>
          </Center>
        </Box>
      </Stack>

      <AsideBox>
        <Heading size="l" mb="4" color="gray" textTransform="uppercase">What we believe in</Heading>
        <Stack>
          <Text><b>Profesional tools should be available to everyone. </b>Our objective and holistic view makes even the most complicated professional questions and data simple to understand. Our goal is to set you on an informed path towards a balanced future.</Text>
          <Text><b>Personalized for you. </b>Tell us what matters to you and we'll recommend things like the best places to live to support your career, simple ways to rebalance your path forward and more.</Text>
          <Text><b>No nonsense. </b>Straightforward questions, smart calculators and honest language help you make sense of your options.</Text>
        </Stack>
      </AsideBox>
    </Layout>
  );
};

export default PageHome;

export const pageQuery = graphql`
  query HomePageQuery(
    $pageName: String!
  ){
    allAirtable(filter: {table: {eq: $pageName}}) {
      nodes {
        data {
          description
          title
          variant {
            data {
              name
            }
          }
        }
      }
    }
  }
`;
