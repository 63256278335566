import * as React from 'react';

import { Box } from '@chakra-ui/react';

const AsideBox = ({ children }) => (
  <Box
    p={{ base: 8, md: 16 }}
    mt={{ base: 8, md: 16 }}
    bg="whiteAlpha.600"
  >
    { children }
  </Box>
);

export default AsideBox;
